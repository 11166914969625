.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal {
  background-color: #0e86e7b3;
}

pre {
  margin-bottom: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.fc-button {
  text-transform: capitalize !important;
}

.pointer {
  cursor: pointer;
}

/*
Modal
*/

.modal {
  display: block !important;
}
.modal-dialog {
  overflow-y: initial !important;
}
.modal-body {
  max-height: 550px;
  overflow-y: auto;
}

/*Creat appoint*/

.autoCompleteUL {
  list-style-type: none;
  padding-left: 0px;
  z-index: 1;
  min-width: 200px;
  position: absolute;
}

.autoCompleteUL > li {
  background-color: aliceblue;
  cursor: pointer;
}

.autoCompleteUL > li:hover {
  background-color: #61dafb;
}

.ulErrorList {
  list-style-type: none;
  padding-left: 0px;
  min-width: 200px;
  color: red;
}
